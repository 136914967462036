<template>
  <div class="task_hall_container">
    <div class="task_hall_title">
      <div class="logo">
        <img src="@/assets/images/task-manager.png" alt="账号管理">
      </div>
      <div style="margin-left: 10px;">账号管理</div>
    </div>

    <!-- 添加账号按钮 -->
    <div class="add_btn">
      <el-button
        size="small"
        type="primary"
        :loading="addBtnLoading"
        @click="addAccountVisible = true">
        添加账号
      </el-button>
    </div>

    <!-- 表格面板 -->
    <el-tabs
      v-model="activeName"
      class="task_hall_table">
      <el-tab-pane
        label="用户账号"
        name="first">
        <user-table></user-table>
      </el-tab-pane>
      <el-tab-pane
        label="企业账号"
        name="second">
        <company-table></company-table>
      </el-tab-pane>
      <el-tab-pane
        label="管理端账号"
        name="third">
        <admin-table></admin-table>
      </el-tab-pane>
    </el-tabs>

    <!-- 添加账号 -->
    <add-account
      :visible.sync="addAccountVisible">
    </add-account>
  </div>
</template>

<script>
import AddAccount from './components/add-account.vue'
import AdminTable from './components/admin-table.vue'
import CompanyTable from './components/company-table.vue'
import UserTable from './components/user-table.vue'

export default {
  components: {
    AddAccount,
    AdminTable,
    UserTable,
    CompanyTable
  },
  data () {
    return {
      activeName: 'first',
      addAccountVisible: false, // 添加账号
      addBtnLoading: false // 添加账号时按钮的加载
    }
  }
}
</script>

<style lang="scss" scoped>
.task_hall_container {
  background-color: #fff;
  height: 100%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .task_hall_title {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
  .add_btn {
    margin-top: 10px;
    margin-bottom: 22px;
  }
}
</style>
