<template>
  <el-dialog
    title="添加账号"
    :visible.sync="$_visible"
    width="500px"
    :show-close="false"
    @close="onDialogClose">
    <el-form
      ref="form"
      label-position="left"
      label-width="100px"
      :rules="rules"
      :model="addAccountForm">
      <el-form-item
        label="手机号"
        prop="mobile">
        <el-input
          size="small"
          style="width: 320px;"
          v-model="addAccountForm.mobile">
        </el-input>
      </el-form-item>
      <el-form-item label="账号类型">
        <el-select
          size="small"
          v-model="addAccountForm.type"
          style="width: 320px;"
          placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="密码"
        prop="password">
        <el-input
          type="password"
          show-password
          size="small"
          style="width: 320px;"
          v-model="addAccountForm.password">
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button
        size="small"
        @click="closeDialog">
        取消
      </el-button>
      <el-button
        size="small"
        type="primary"
        :loading="btnLoading"
        @click="addAccount">
        添加
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from '@/api/index.js'
import SHA256 from 'crypto-js/sha256'

export default {
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  data () {
    return {
      addAccountForm: {
        type: '后台管理员',
        mobile: '',
        password: ''
      },
      options: [{
        value: '0',
        label: '后台管理员'
      }],
      btnLoading: false,
      // 表单验证规则
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号格式错误', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,100}$/, message: '密码长度为8~16位，至少需要包含数字、字母', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    addAccount () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          api.adminAdd({
            mobile: this.addAccountForm.mobile,
            password: SHA256(this.addAccountForm.password).toString()
          }).then((res) => {
            if (res.data.code === 0) {
              this.$message.success('创建成功')
            } else {
              this.$message.error('创建失败')
            }
          }).catch((error) => {
            console.log(error)
            this.$message.error('请求出错')
          }).finally(() => {
            this.btnLoading = false
            this.closeDialog()
          })
        } else {
          return false
        }
      })
    },
    onDialogClose () {
      // 将表单重置
      this.$refs.form.resetFields()
    },
    // 关闭dialog
    closeDialog () {
      this.$_visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  padding: 0 20px 20px 20px;
  .el-dialog__header {
    height: 60px;
    padding: 0;
    line-height: 60px;
    font-size: 16px;
    font-weight: bold;
    color: #303133;
  }
  .el-dialog__body {
    padding: 0 20px 0 20px;
    margin-bottom: 40px;
  }
  .el-dialog__footer {
    padding: 0;
  }
}
</style>
