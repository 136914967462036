<template>
  <div>
    <el-table
      :data="tableList"
      class="table_border"
      :cell-style="cellStyle"
      v-loading="isLoading">
      <el-table-column
        type="index"
        label="序号"
        :index="indexMethod"
        width="100">
      </el-table-column>
      <el-table-column
        label="账号"
        prop="mobile">
      </el-table-column>
      <el-table-column
        label="用户名"
        prop="username">
      </el-table-column>
      <el-table-column
        label="注册时间"
        prop="createdAt">
      </el-table-column>
      <el-table-column label="权限" width="80">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status === 1 || scope.row.status === 3"
            style="color: #F56C6C;"
            type="text"
            @click="forbidden(scope.row.id)">
            禁用
          </el-button>
          <el-button
            v-else
            type="text"
            @click="startUsing(scope.row.id)">
            启用
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <pagination
      @paginationChange="paginationChange"
      :pageInfo="pageInfo">
    </pagination>
  </div>
</template>

<script>
import Pagination from '@/components/pagination/index.vue'
import api from '@/api/index'

export default {
  data () {
    return {
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      isLoading: false,
      tableList: []
    }
  },
  components: {
    Pagination
  },
  created () {
    this.getList()
  },
  methods: {
    cellStyle () {
      return 'height: 48px; padding: 0px;'
    },
    paginationChange (newNum) {
      this.pageInfo.pageNum = newNum
      this.getList()
    },
    indexMethod (index) {
      // 当前页数 - 1 * 每页数据条数 + index + 1
      return (this.pageInfo.pageNum - 1) * this.pageInfo.pageSize + index + 1
    },
    // 禁用改账号
    forbidden (id) {
      api.adminUserDisable(id).then(res => {
        if (res.data.code === 0) {
          this.$message.success('禁用成功')
          this.getList()
        } else {
          this.$message.error('禁用失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 启用改账号
    startUsing (id) {
      api.adminUserEnable(id).then(res => {
        if (res.data.code === 0) {
          this.$message.success('启用成功')
          this.getList()
        } else {
          this.$message.error('启用失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 获取账号列表
    getList () {
      this.isLoading = true
      api.adminUserList({
        ...this.pageInfo,
        role: 0
      }).then(res => {
        if (res.data.code === 0) {
          const { total, list, currentPage } = res.data.data
          this.tableList = list
          this.pageInfo.total = total
          this.pageInfo.pageNum = currentPage
        } else {
          this.$message.error('获取信息失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.table_border {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
}
</style>
